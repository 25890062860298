.page {
  width: 100%;
  margin-top: -1px;

  background-color: #ECEEF1;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 64px;

    .left_block {
      flex: 0.5;
      max-width: 736px;
      padding: 30px;
      margin: 0 auto;
    }
    .right_block {
      flex: 0.5;
      max-width: 50%;
      margin-top: -1px;
      height: auto;

      img {
        object-fit: cover;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1650px) {
  .page {
    &__container {
      gap: 32px;

      .right_block {
        width: 830px;
        height: 515px;
        background-image: url("../../../shared/assets/images/open-account/companies.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        img {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 1450px) {
  .page {
    &__container {
      .right_block {
        width: 625px;
        height: 545px;
      }
    }
  }
}
@media (max-width: 1250px) {
  .page {
    &__container {
      .right_block {
        width: 570px;
        height: 545px;
      }
    }
  }
}
@media (max-width: 850px) {
  .page {
    &__container {
      gap: 16px;

      .right_block {
        width: 370px;
        height: 545px;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {
      gap: 0;

      .left_block {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        form {
          max-width: 500px;
          padding: 30px;

          h3 {
            font-size: 36px;
            font-weight: 700;
            line-height: 47px;
            letter-spacing: 0;
            text-align: left;
          }

          button {
            max-width: 300px;
            align-self: center;
            margin-top: 15px;
          }
        }
      }

      .right_block {
        display: none;
      }
    }
  }
}
@media (max-width: 650px) {
  .page {
    &__container {
      .left_block {
        form {
          h3 {
            font-size: 36px;
            line-height: 47px;
            letter-spacing: 0;
          }
          p {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0;
          }

          button {
            max-width: 300px;
            align-self: center;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .page {
    form {
      button {
        max-width: 100% !important;
      }
    }
  }
}