.page {
  width: 100%;
  padding: 30px 0;
  background-color: #F8F8F8;

  &__container {
    display: flex;
    gap: 64px;

    .left_block {
      display: flex;
      flex-wrap: nowrap;
      min-width: 514px;
      max-width: 100%;

      &__content {
        width: 100%;

        img {
          max-width: 504px;
        }
      }

      .title {
        margin-bottom: 32px;
        text-transform: uppercase;
        color: #1B192A;

        font-size: 46px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 0;
        text-align: left;

        span {
          display: block;
          color: #72BF44;
        }
      }
    }
    .right_block {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 21px;

      .cards__desktop {
        display: flex;
        flex-wrap: wrap;

        li {
          max-width: 308px;
          padding: 20px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      gap: 30px;
      padding: 0 10px 0 30px;

      .right_block {
        .cards__desktop {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .page {
    &__container {
      gap: 30px;
      padding: 0 10px 0 30px;

      .left_block {
        min-width: 400px;

        .title {
          margin-bottom: 15px;
          font-size: 3vw;
          line-height: 3.5vw;
        }
      }

      .right_block {
        .cards__desktop {
          display: flex;
          flex-wrap: nowrap;
          gap: 15px;

          li {
            max-width: 220px;
            height: 210px;
            padding: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 1080px) {
  .page {
    &__container {
      gap: 15px;

      .left_block {
        min-width: 300px;
      }

      .right_block {
        .cards__desktop {
          gap: 15px;

          li {
            max-width: 200px;
            height: 220px;
            padding: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .page {
    &__container {
      gap: 15px;

      .left_block {
        min-width: 300px;

        img {
          max-width: 400px;
        }
      }

      .right_block {
        .cards__desktop {
          gap: 15px;

          li {
            max-width: 200px;
            height: 190px;
            padding: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .page {
    &__container {
      gap: 15px;

      .left_block {
        min-width: 300px;

        img {
          max-width: 350px;
        }
      }

      .right_block {
        .cards__desktop {
          gap: 15px;

          li {
            max-width: 150px;
            height: 200px;
            padding: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;
      padding-bottom: 32px;

      .left_block {
        min-width: 100%;
        margin-bottom: 32px;

        &__content {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .title {
          margin-bottom: 0;

          font-size: 4vw;
          line-height: 6vw;
          text-align: center;
        }
      }
      .right_block {
        height: auto;
      }
    }
  }
}
@media (max-width: 650px) {
  .page {
    &__container {
      .left_block {
        .title {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.02em;
        }
      }
    }
  }
}